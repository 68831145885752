import React from 'react';
import './index.css';

const About = () => {
  return (
    <div className='h-full w-full absolute top-0 left-0'>
      {/* <div className='md:mx-96 md:pt-40 md:pb-40 mt-28'>
        <div className='bg-white w-fit mx-auto mb-12 p-2 pink-shadow'>
          <h2 className='md:text-5xl text-3xl md:text-left text-center font-poppins font-bold leading-loose uppercase'>About me</h2>
        </div>
        <div className='bg-white w-fit p-6 pink-shadow font-anonymous'>
          <p className='text-left my-8 md:text-lg text-lg font-bold'>Hi! My name is Ryan Hubbuck, and I'm a front-end developer currently living in Knoxville, Tennessee.</p>
          <p className='text-left my-8 md:text-lg text-lg font-bold'>I am a self-taught front-end developer who has been working freelance since 2020. 
          Coding is my passion, and I am dedicated to staying up-to-date on new technologies and platforms.</p>
          <p className='text-left my-8 md:text-lg text-lg font-bold'>If you are in need of help with 
          your personal or small-business website, I would love the opportunity to help you make 
          the most of your online presence.</p>
          <p className='text-left my-8 md:text-lg text-lg font-bold'>I look forward to hearing from you!</p>
        </div>
      </div> */}
      <p>
        lorem impus this is my descriptions lblahd kfaskdfhkal  lorem impus this is my descriptions lblahd kfaskdfhkal   lorem impus this is my descriptions lblahd kfaskdfhkal   lorem impus this is my descriptions lblahd kfaskdfhkal 
      </p>
    </div>
  )
}

export default About